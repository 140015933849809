<template>
  <div class="photo-coupes">
    <h1 class="subtitle-1 grey--text">Расписание работы фото-купе</h1>

    <v-container fluid>
      <v-row class="grey lighten-3">
        <v-col class="flex-grow-0">
          <PhotoCoupeDialogUpload :projectId="projectId" />
        </v-col>
        <v-col md="4">
          <v-menu
            ref="dateRangePicker"
            v-model="isDateRangePickerVisible"
            :close-on-content-click="false"
            :return-value.sync="dateRange"
            min-width="290px"
            offset-y
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateRangeText" v-bind="attrs" v-on="on" dense flat hide-details prepend-inner-icon="mdi-calendar-range" readonly solo />
            </template>
            <v-date-picker v-model="datePickerRange" no-title range scrollable @change="onDatePickerRangeChange">
              <v-spacer />
              <v-btn color="primary" text @click="isDateRangePickerVisible = false">Отмена</v-btn>
              <v-btn color="primary" text @click="onDatePickerRangeSave">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <PhotoCoupeTable :projectId="projectId" :items="filteredItems" :loading="loading" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PhotoCoupeDialogUpload from '@/components/PhotoCoupeDialogUpload'
import PhotoCoupeTable from '@/components/PhotoCoupeTable'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    const today = this.$moment().format('YYYY-MM-DD')
    return {
      loading: false,
      isDateRangePickerVisible: false,
      datePickerRange: [today, today],
      dateRange: [today, today],
      currentDate: today
    }
  },
  components: {
    PhotoCoupeDialogUpload,
    PhotoCoupeTable
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    filteredItems() {
      return this.photoCoupes.filter((r) => this.$moment(r.date).isBetween(this.dateRange[0], this.dateRange[1], undefined, '[]'))
    },
    dateRangeText() {
      const dateFrom = this.$moment(this.datePickerRange[0])
      const dateTo = this.$moment(this.datePickerRange[1])
      return this.formatDateRangeText(dateFrom, dateTo)
    },
    ...mapGetters('project', ['photoCoupes'])
  },
  mounted() {
    this.fetchPhotoCoupes(this.projectId)
  },
  methods: {
    onDatePickerRangeChange() {
      if (this.datePickerRange[1] < this.datePickerRange[0]) {
        this.datePickerRange.reverse()
      }
    },
    onDatePickerRangeSave() {
      if (this.datePickerRange.length === 1) {
        this.datePickerRange.push(this.currentDate)
      }
      this.$refs.dateRangePicker.save(this.datePickerRange)
    },
    formatDateRangeText(dateFrom, dateTo) {
      if (dateTo.diff(dateFrom) === 0) {
        return `${dateFrom.format('D MMM')}`
      }
      let dateFromFormat, dateToFormat
      dateFromFormat = dateToFormat = 'D MMM YYYY'
      if (dateFrom.year() === dateTo.year()) {
        if (dateFrom.year() === this.$moment(this.currentDate).year()) {
          dateToFormat = 'D MMM'
        }
        if (dateFrom.month() === dateTo.month()) {
          dateFromFormat = 'D'
        } else {
          dateFromFormat = 'D MMM'
        }
      }

      return `С ${dateFrom.format(dateFromFormat)} по ${dateTo.format(dateToFormat)}`
    },
    ...mapActions('project', ['fetchPhotoCoupes'])
  }
}
</script>
