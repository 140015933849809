<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :loading="loading"
    :server-items-length="items.length"
    hide-default-footer
    loading-text="Загрузка..."
    no-data-text="Нет записей"
    no-results-text="Записи не найдены"
  >
    <template v-slot:top>
      <v-dialog v-model="dialog" max-width="300">
        <v-card>
          <v-card-title>Изменить статус</v-card-title>
          <v-card-text>
            <v-select v-model="editedItem.status" :items="statuses" item-value="code" label="Изменить" single-line required />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeEditDialog">Отмена</v-btn>
            <v-btn text color="primary" @click="save">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.status="{ item }">
      {{ getStatusTextByCode(item.status) }}
      <v-btn text small @click="editItem(item)">
        <v-icon small> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <template v-slot:item.createdAt="{ item }">
      {{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}
    </template>
    <template v-slot:item.updatedAt="{ item }">
      {{ item.updatedAt | moment('YYYY-MM-DD HH:mm:ss') }}
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    projectId: String,
    items: Array,
    loading: Boolean
  },
  data() {
    return {
      headers: [
        { text: 'Дата', value: 'date' },
        { text: 'Поезд', value: 'train' },
        { text: 'Время', value: 'time' },
        { text: 'Тип', value: 'type' },
        { text: 'Статус', value: 'status' },
        { text: 'Запись создана', value: 'createdAt' },
        { text: 'Запись обновлена', value: 'updatedAt' }
      ],
      statuses: [
        { text: 'Доступно', code: 0 },
        { text: 'Забронировано', code: 1 },
        { text: 'Оплачено', code: 2 },
        { text: 'Отменено', code: 3 }
      ],
      defaultItem: { status: null },
      editedItem: { status: null },
      editedIndex: -1,
      dialog: false
    }
  },
  watch: {
    dialog(val) {
      val || this.closeEditDialog()
    }
  },
  methods: {
    getStatusTextByCode(code) {
      return this.statuses.find((status) => status.code === code).text || `Неизвестный код статуса: ${code}`
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    closeEditDialog() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      this.updatePhotoCoupe({ projectId: this.projectId, photoCoupe: this.editedItem }).then(() => {
        this.closeEditDialog()
      })
    },
    ...mapActions('project', ['updatePhotoCoupe'])
  }
}
</script>
